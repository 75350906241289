import {
  ApartmentRounded,
  ExpandMore,
  ShortcutOutlined,
} from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Link,
  Typography,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import CustomCard from '../../common/CustomCard';
import LoadingSkeleton from '../../common/LoadingSkeleton';
import StyledIcon from '../../common/StyledIcon';
import { ReactComponent as AiSparkle } from '../../common/icons/aiSparkle.svg';
import { formatCurrency } from '../../utils/functions';
import { findSupplierHierarchy } from '../../utils/supplierHierarchyDemoData';
import { firstTimeframeWithData } from './SupplierDetailsSpend';

const formatTitleTotal = (timeframe) => {
  return timeframe === 'max' ? 'all time' : `in last ${timeframe} months`;
};

const formatSupplierNames = (invoices, timeframe, supplierName) => {
  let total = 0;
  const startDate =
    timeframe === 'max'
      ? moment(invoices[0]?.issue_date || moment()).subtract(1, 'day')
      : moment().subtract(timeframe, 'month');

  const invoiceAccounts = [...invoices].reduce((acc, entry) => {
    const name = entry.name || supplierName;
    const description =
      entry.description_ai === 'unknown' ? '' : entry.description_ai;
    const categories = description ? entry.categories_ai : [];
    if (!acc[name]) {
      acc[name] = { name, value: 0, categories, description };
    }
    return acc;
  }, {});

  [...invoices]
    .sort((a, b) => new Date(a.issue_date) - new Date(b.issue_date))
    .filter((entry) => {
      if (timeframe === 'max') return true;
      return !moment(entry.issue_date).isBefore(startDate);
    })
    .forEach((entry) => {
      const name = entry.name || supplierName;
      if (!invoiceAccounts[name]) {
        invoiceAccounts[name] = { name: name, value: 0 };
      }
      total += Number(entry.amount_usd_total || 0);
      invoiceAccounts[name].value += Number(entry.amount_usd_total || 0);
    });

  const combinedInvoices = Object.values(invoiceAccounts);
  return { total, combinedInvoices };
};

export const tooltipDescripton = (description, categories) => {
  return (
    <CustomCard height={'100%'}>
      <Box padding={'16px'}>
        <Box display={'flex'} gap={'6px'}>
          <Box padding={'3px 2px'}>
            <AiSparkle />
          </Box>
          <Box display={'flex'} flexDirection={'column'} gap={'6px'}>
            <Box
              fontSize={(theme) => theme.typography.caption}
              color={(theme) => theme.palette.text.secondary}
            >
              {description}
            </Box>
            <Box display={'flex'} gap={'8px'} flexWrap={'wrap'}>
              {categories.map((category, index) => (
                <Box
                  key={index}
                  sx={{
                    border: '1px solid #A7A4E0',
                    borderRadius: '100px',
                    padding: '3px 10px',
                    fontSize: '12px',
                    fontWeight: '400',
                    color: '#6558B1',
                    textWrap: 'no-wrap',
                  }}
                >
                  {category}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    </CustomCard>
  );
};

const SupplierAccountItem = ({ name, value }) => {
  return (
    <Box display={'flex'} alignContent={'center'} gap={'4px'} key={name}>
      <Box
        fontSize={(theme) => theme.typography.subtitle2}
        color={(theme) => theme.palette.text.primary}
        width={'250px'}
      >
        {name}
      </Box>
      <Box
        fontSize={(theme) => theme.typography.body2}
        color={(theme) => theme.palette.text.primary}
      >
        {formatCurrency(value)}
      </Box>
    </Box>
  );
};

const SupplierDetailsStructure = ({
  invoices,
  timeframe,
  sidebar,
  supplierName,
  supplierId,
  summary,
  handleClick,
}) => {
  const [spendTotal, setSpendTotal] = useState();
  const [accounts, setAccounts] = useState([]);
  const [filteredAccounts, setFilteredAccounts] = useState([]);
  const [localTimeframe, setLocalTimeframe] = useState(timeframe);
  const [supplierHierarchy, setSupplierHierarchy] = useState({});
  const [supplierHierarchyLoading, setSupplierHierarchyLoading] =
    useState(true);

  const navigate = useNavigate();
  const { pathname, search } = useLocation();
  const { page } = useSelector((state) => state.appSlice);
  useEffect(() => {
    if (!invoices && supplierId && findSupplierHierarchy(supplierId)) {
      setSpendTotal(0);
      setAccounts([]);
      setFilteredAccounts([]);
      setLocalTimeframe(timeframe);
      return;
    }

    const calculatedTimeframe = summary
      ? firstTimeframeWithData(invoices)
      : timeframe;
    setLocalTimeframe(calculatedTimeframe);
    const { total, combinedInvoices } = formatSupplierNames(
      invoices,
      calculatedTimeframe,
      supplierName,
    );
    const sortedAccounts = combinedInvoices.sort((a, b) => b.value - a.value);
    const filteredCombinedInvoices = summary
      ? sortedAccounts.slice(0, 5)
      : sortedAccounts;
    setSpendTotal(total);
    setAccounts(sortedAccounts);
    setFilteredAccounts(filteredCombinedInvoices);
  }, [invoices, timeframe, summary, supplierId]);

  useEffect(() => {
    if (!supplierId || !localTimeframe) return;
    const supplierHierarchy = findSupplierHierarchy(supplierId, localTimeframe);
    setSupplierHierarchy(supplierHierarchy);
    setSupplierHierarchyLoading(false);
  }, [supplierId, localTimeframe]);

  if (supplierHierarchyLoading) {
    return <LoadingSkeleton variant={'interactionItem'} />;
  }


  const handleSupplierClick = (supplierId) => {
    const detailsLink = `${page === 'overview' ? '' : `/${page}`}/details/supplier/${supplierId}${pathname.includes('fullscreen') ? '/fullscreen' : ''}/5${search}`;
    handleClick()
    navigate(detailsLink);
  };

  return (
    <CustomCard height={'100%'}>
      <Box
        display={'flex'}
        flexDirection={'column'}
        padding={'16px'}
        gap={'16px'}
      >
        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          {!Boolean(summary) && (
            <Box
              color={(theme) => theme.palette.text.main}
              fontSize={(theme) =>
                sidebar ? theme.typography.h6 : theme.typography.h5
              }
            >
              Structure & spend
            </Box>
          )}
          <Box
            color={(theme) => theme.palette.text.main}
            fontSize={(theme) =>
              summary ? theme.typography.h6 : theme.typography.subtitle1
            }
            display={'flex'}
            alignItems={'center'}
            gap={'4px'}
          >
            {formatCurrency(spendTotal)}
            <Typography
              fontSize={(theme) => theme.typography.body1}
              color={(theme) => theme.palette.text.secondary}
            >
              {formatTitleTotal(localTimeframe)}
            </Typography>
          </Box>
          {Boolean(summary) && (
            <Link
              sx={{ cursor: 'pointer' }}
              color="secondary"
              fontSize={(theme) => theme.typography.subtitle2}
              underline="hover"
              onClick={() => {
                summary();
              }}
            >
              See structure & spend
            </Link>
          )}
        </Box>
        {supplierHierarchy.parentFormatted && (
          <Accordion
            sx={{
              boxShadow: 'none',
              border: '1.5px solid',
              borderRadius: '8px',
              borderColor: '#E0E0E0',
              padding: '0 16px',
              '&.Mui-expanded': {
                margin: '0 !important',
                borderColor: '#E0E0E0',
              },
            }}
          >
            <AccordionSummary
              expandIcon={supplierHierarchy.parentFormatted.accounts?.length > 0 ? <StyledIcon component={ExpandMore} color="icon" /> : null}
              sx={{
                display: 'flex',
                padding: 0,
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '0 !important',
                  borderBottom: '1px solid #E0E0E0',
                },
                '& .MuiAccordionSummary-content': {
                  gap: '4px',
                  alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '16px 0',
                  alignItems: 'center',
                },
              }}
            >
              <Box
                width={'250px'}
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                gap={'8px'}
              >
                <StyledIcon component={ApartmentRounded} color="icon" />
                <Link
                  color="secondary"
                  fontSize={(theme) => theme.typography.subtitle1}
                  underline="hover"
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => handleSupplierClick(supplierHierarchy.parentFormatted.id)}
                >
                  {supplierHierarchy.parentFormatted.name}
                </Link>
                <Box
                  fontSize={(theme) => theme.typography.caption}
                  color={(theme) => theme.palette.text.primary}
                  sx={{
                    padding: '2px 4px',
                    borderRadius: '4px',
                    background: '#00000014',
                  }}
                >
                  Parent
                </Box>
              </Box>
              {supplierHierarchy.parentFormatted.accounts?.length > 0 && (
                <Box>{`${
                  supplierHierarchy.parentFormatted.accounts?.length
                } account${
                  supplierHierarchy.parentFormatted.accounts?.length === 1
                    ? ''
                    : 's'
                }`}</Box>
              )}
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: '0',
                padding: '0',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                padding={'16px 0'}
                gap={'8px'}
              >
                {supplierHierarchy.parentFormatted.accounts.map(
                  ({ name, value, description, categories }) => {
                    if (description) {
                      return (
                        <Accordion
                          elevation={0}
                          sx={{
                            padding: '0px',
                            '&:before': {
                              display: 'none',
                            },
                            '&.Mui-expanded': {
                              margin: '0 !important',
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <StyledIcon component={ExpandMore} color="icon" />
                            }
                            sx={{
                              display: 'flex',
                              padding: 0,
                              '&.Mui-expanded': {
                                margin: '0 !important',
                                minHeight: '0 !important',
                              },
                              '& .MuiAccordionSummary-content': {
                                alignItems: 'center',
                                margin: 0,
                              },
                              '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: '16px 0',
                                alignItems: 'center',
                              },
                            }}
                          >
                            <SupplierAccountItem
                              key={name}
                              name={name}
                              value={value}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              margin: '0',
                              padding: '0',
                              borderTop: '0px',
                              paddingBottom: '16px',
                            }}
                          >
                            {tooltipDescripton(description, categories)}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                    return (
                      <SupplierAccountItem
                        key={name}
                        name={name}
                        value={value}
                      />
                    );
                  },
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Box display={'flex'} flexDirection={'row'} gap={'8px'}>
          {supplierHierarchy.parentFormatted && (
            <Box paddingTop={'16px'}>
              <StyledIcon
                sx={{
                  rotate: '180deg',
                  transform: 'scale(-1, 1)',
                }}
                component={ShortcutOutlined}
                color="icon"
              />
            </Box>
          )}
          <Accordion
            defaultExpanded={filteredAccounts?.length > 0}
            sx={{
              boxShadow: 'none',
              border: '1.5px solid',
              borderRadius: '8px',
              borderColor: '#6558B1',
              padding: '0 16px',
              flexGrow: '1',
              '&.Mui-expanded': {
                margin: '0 !important',
                borderColor: '#6558B1',
              },
            }}
          >
            <AccordionSummary
              expandIcon={filteredAccounts?.length === 0 ? null : <StyledIcon component={ExpandMore} color="icon" />}
              sx={{
                display: 'flex',
                padding: 0,
                '&.Mui-expanded': {
                  margin: '0 !important',
                  minHeight: '0 !important',
                  borderBottom: '1px solid #E0E0E0',
                },
                '& .MuiAccordionSummary-content': {
                  gap: '4px',
                  alignItems: 'center',
                },
                '& .MuiAccordionSummary-content.Mui-expanded': {
                  margin: '16px 0',
                  alignItems: 'center',
                },
              }}
            >
              <Box
                width={'250px'}
                display={'flex'}
                flexWrap={'wrap'}
                alignItems={'center'}
                gap={'8px'}
              >
                <StyledIcon component={ApartmentRounded} color="icon" />
                <Box
                  fontSize={(theme) => theme.typography.subtitle1}
                  color={(theme) => theme.palette.text.primary}
                >
                  {supplierName}
                </Box>
                <Box
                  fontSize={(theme) => theme.typography.caption}
                  color={(theme) => theme.palette.text.primary}
                  sx={{
                    padding: '2px 4px',
                    borderRadius: '4px',
                    background: '#00000014',
                  }}
                >
                  Currently viewing
                </Box>
              </Box>
              <Box>
                {accounts.length > 0 &&
                  `${accounts.length} account${
                    accounts.length === 1 ? '' : 's'
                  }`}
              </Box>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                margin: '0',
                padding: '0',
              }}
            >
              <Box
                display={'flex'}
                flexDirection={'column'}
                padding={'16px 0'}
                gap={'8px'}
              >
                {filteredAccounts.map(
                  ({ name, value, description, categories }) => {
                    if (description) {
                      return (
                        <Accordion
                          elevation={0}
                          sx={{
                            padding: '0px',
                            '&:before': {
                              display: 'none',
                            },
                            '&.Mui-expanded': {
                              margin: '0 !important',
                            },
                          }}
                        >
                          <AccordionSummary
                            expandIcon={
                              <StyledIcon component={ExpandMore} color="icon" />
                            }
                            sx={{
                              display: 'flex',
                              padding: 0,
                              '&.Mui-expanded': {
                                margin: '0 !important',
                                minHeight: '0 !important',
                              },
                              '& .MuiAccordionSummary-content': {
                                alignItems: 'center',
                                margin: 0,
                              },
                              '& .MuiAccordionSummary-content.Mui-expanded': {
                                margin: '16px 0',
                                alignItems: 'center',
                              },
                            }}
                          >
                            <SupplierAccountItem
                              key={name}
                              name={name}
                              value={value}
                            />
                          </AccordionSummary>
                          <AccordionDetails
                            sx={{
                              margin: '0',
                              padding: '0',
                              borderTop: '0px',
                              paddingBottom: '16px',
                            }}
                          >
                            {tooltipDescripton(description, categories)}
                          </AccordionDetails>
                        </Accordion>
                      );
                    }
                    return (
                      <SupplierAccountItem
                        key={name}
                        name={name}
                        value={value}
                      />
                    );
                  },
                )}
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>
        {supplierHierarchy.childrenFormatted && (
          <>
            {supplierHierarchy.childrenFormatted.map((child) => {
              return (
                <Box
                  key={child.id}
                  display={'flex'}
                  flexDirection={'row'}
                  gap={'8px'}
                >
                  <Box paddingTop={'16px'}>
                    <StyledIcon
                      sx={{
                        rotate: '180deg',
                        transform: 'scale(-1, 1)',
                      }}
                      component={ShortcutOutlined}
                      color="icon"
                    />
                  </Box>
                  <Accordion
                    sx={{
                      boxShadow: 'none',
                      border: '1.5px solid',
                      borderRadius: '8px',
                      borderColor: '#E0E0E0',
                      padding: '0 16px',
                      flexGrow: '1',
                      '&.Mui-expanded': {
                        margin: '0 !important',
                        borderColor: '#E0E0E0',
                      },
                    }}
                  >
                    <AccordionSummary
                      expandIcon={
                        child.accounts?.length > 0 ? <StyledIcon component={ExpandMore} color="icon" /> : null
                      }
                      sx={{
                        display: 'flex',
                        padding: 0,
                        '&.Mui-expanded': {
                          margin: '0 !important',
                          minHeight: '0 !important',
                          borderBottom: '1px solid #E0E0E0',
                        },
                        '& .MuiAccordionSummary-content': {
                          gap: '4px',
                          alignItems: 'center',
                        },
                        '& .MuiAccordionSummary-content.Mui-expanded': {
                          margin: '16px 0',
                          alignItems: 'center',
                        },
                      }}
                    >
                      <Box
                        width={'250px'}
                        display={'flex'}
                        flexWrap={'wrap'}
                        alignItems={'center'}
                        gap={'8px'}
                      >
                        <StyledIcon component={ApartmentRounded} color="icon" />
                        <Link
                          color="secondary"
                          fontSize={(theme) => theme.typography.subtitle1}
                          underline="hover"
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            cursor: 'pointer',
                          }}
                          onClick={() => handleSupplierClick(child.id)}
                        >
                          {child.name}
                        </Link>
                        <Box
                          fontSize={(theme) => theme.typography.caption}
                          color={(theme) => theme.palette.text.primary}
                          sx={{
                            padding: '2px 4px',
                            borderRadius: '4px',
                            background: '#00000014',
                          }}
                        >
                          Subsidiary
                        </Box>
                      </Box>
                      <Box>{`${child.accounts?.length || 1} account${
                        child.accounts?.length === 1 ? '' : 's'
                      }`}</Box>
                    </AccordionSummary>
                    <AccordionDetails
                      sx={{
                        margin: '0',
                        padding: '0',
                      }}
                    >
                      <Box
                        display={'flex'}
                        flexDirection={'column'}
                        padding={'16px 0'}
                        gap={'8px'}
                      >
                        {child.accounts.map(
                          ({ name, value, description, categories }) => {
                            if (description) {
                              return (
                                <Accordion
                                  elevation={0}
                                  sx={{
                                    padding: '0px',
                                    '&:before': {
                                      display: 'none',
                                    },
                                    '&.Mui-expanded': {
                                      margin: '0 !important',
                                    },
                                  }}
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      <StyledIcon
                                        component={ExpandMore}
                                        color="icon"
                                      />
                                    }
                                    sx={{
                                      display: 'flex',
                                      padding: 0,
                                      '&.Mui-expanded': {
                                        margin: '0 !important',
                                        minHeight: '0 !important',
                                      },
                                      '& .MuiAccordionSummary-content': {
                                        alignItems: 'center',
                                        margin: 0,
                                      },
                                      '& .MuiAccordionSummary-content.Mui-expanded':
                                        {
                                          margin: '16px 0',
                                          alignItems: 'center',
                                        },
                                    }}
                                  >
                                    <SupplierAccountItem
                                      key={name}
                                      name={name}
                                      value={value}
                                    />
                                  </AccordionSummary>
                                  <AccordionDetails
                                    sx={{
                                      margin: '0',
                                      padding: '0',
                                      borderTop: '0px',
                                      paddingBottom: '16px',
                                    }}
                                  >
                                    {tooltipDescripton(description, categories)}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            }
                            return (
                              <SupplierAccountItem
                                key={name}
                                name={name}
                                value={value}
                              />
                            );
                          },
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              );
            })}
          </>
        )}
      </Box>
    </CustomCard>
  );
};

export default SupplierDetailsStructure;
